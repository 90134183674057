@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&display=swap);
*:not(strong) {
  box-sizing: border-box;
  font-family: "Big Shoulders Text", sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  display: flex;
  font-size: 16px;
}

#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

.rootContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 34px;
  font-weight: 500;
  word-break: break-word;
}

.subHeading {
  font-size: 17px;
  line-height: 30px;
  word-break: break-word;
}

a {
  color: #9f1c44;
}

::placeholder {
  color: #999;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

.hide,
.mobile_show,
.logoSection {
  display: none !important;
}

.text-center {
  text-align: center;
}

.dropdownLang {
  position: relative;
}

.dropdownLang ul {
  background-color: #9f1c44;
  display: none;
  list-style: none;
  position: absolute;
  padding: 0;
  top: 100%;
  right: 0;
}

.dropdownLang:hover ul {
  display: block;
}

.dropdownLang li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.dropdownLang li:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dropdownLang li button {
  color: #fff;
  width: 120px;
  padding: 5px 10px;
}

.switchLang {
  background-color: #9f1c44;
  border-radius: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: auto;
  width: 70px;
  padding: 4px;
}

.switchLang span {
  border-radius: 50px;
  cursor: pointer;
  padding: 2px 9px;
}

.switchLang .active {
  background-color: #fff;
  color: #9f1c44;
}

.centerContent {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  margin: 50px auto;
  flex-direction: row;
}

.accountBox {
  width: 100%;
  /* min-height: calc(100vh - 50px); */
  padding: 80px 50px;
}

.sectionContent {
  border: 3px solid #9f1c44;
  border-radius: 21px;
  color: #000;
  text-align: center;
  min-height: 350px;
  width: 468px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
}

.accountContent {
  border: 3px solid #9f1c44;
  width: 600px;
  max-width: 100%;
  color: #000;
  padding: 30px;
  font-size: 18px;
  margin: auto;
}

.siderBarMenu {
  display: flex;
  align-items: center;
  padding: 0 50px;
  width: 100%;
  height: 80px;
}

.siderBarMenu header {
  width: 200px;
}

.menuList {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: flex-end;
  width: calc(100% - 200px);
  grid-gap: 3%;
  gap: 3%;
  margin: 0;
}

.siderBarMenu button {
  background: none;
  color: #9f1c44;
  border: none;
  padding: 0;
  font: inherit;
  font-size: 20px;
  cursor: pointer;
  outline: inherit;
  line-height: 35px;
  text-align: left;
  margin: 0;
}

.siderBarMenu a {
  font-size: 20px;
  color: #9f1c44;
  text-decoration: none;
  line-height: 35px;
}

.siderBarMenu ul svg {
  display: none;
}

.siderBarMenu .logo {
  margin: 15px 0;
  width: 150px;
  height: auto;
}

.mobilemenuIcon {
  display: none;
}

.sectionTitle {
  line-height: 40px;
  text-align: center;
  margin: 0;
}

.pkfullw .sectionTitle {
  font-size: 38px;
  line-height: 40px;
}

.app-form {
  padding: 0 15px;
  overflow: hidden;
}

.inputStyle {
  color: #000;
  display: block;
  border: 1px solid #9f1c44;
  background-color: transparent;
  margin: auto;
  padding: 15px;
  outline: none;
  width: 100%;
  max-width: 100%;
  margin-bottom: 16px;
  font-size: 16px;
}

.inputStyle:active,
.inputStyle:focus {
  background-color: #fff;
  color: #000;
}

.buttonStyle {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f9e2ed;
  border: 3px solid #9f1c44;
  border-radius: 0;
  color: #000;
  transition: background 0.8s;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.buttonStyle:hover {
  background: #9f1c44;
  color: #fff;
  transition: background 0.8s;
}

.selectionBoxStyle {
  background: transparent;
  border: 2px solid #9f1c44;
  border-radius: 0;
  cursor: pointer;
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 16px;
  display: block;
  font-size: 18px;
  color: #000;
  text-align: left;
  position: relative;
  padding: 10px 45px 10px 15px;
  transition: background 0.8s;
}

.selectionBoxStyle:hover,
.selectionBoxStyle.active {
  background: #58062a;
  color: #fff;
  transition: background 0.8s;
}

.selectionBoxStyle .active_plan {
  right: 10px;
  top: 10px;
}

.selectionBoxStyle .plansPage {
  right: 50px;
  top: 14px;
}

.selectionBoxStyle h5 {
  margin-top: 10px;
}

.errorMessage {
  border-radius: 8px;
  box-shadow: none;
  color: #ffffff;
  background-color: #ff0302;
  border-width: 0;
  margin: auto;
  padding: 15px;
  margin-bottom: 16px;
}

.app-form.signin-form,
.app-form.signup-form {
  max-width: 475px;
  width: 100%;
  margin: auto;
}

.landingPage {
  width: 100%;
  min-height: 100vh;
  margin-top: 50px;
  padding: 0 50px;
}

.landingPage .centerContent {
  align-items: flex-start;
  margin-top: 0;
  grid-gap: 15px;
  gap: 15px;
}

.landingPage .logo {
  display: block;
  width: 220px;
  height: auto;
  margin: 0 auto 20px;
}

.landingPage .steps.sectionContent {
  text-align: left;
}

.landingPage .steps .sectionTitle {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: left;
}

.planList {}

.planWrap {
  padding: 25px 45px 18px 20px;
}

.productTrial {
  background: #9f1c44;
  color: #fff;
  border-radius: 0;
  position: absolute;
  right: 46px;
  top: 28px;
  margin: 0;
  padding: 5px 8px 6px;
  letter-spacing: 0;
  font-size: 15px;
  line-height: 14px;
}

.productName {
  border-bottom: 1px solid #9f1c44;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 5px;
  letter-spacing: 0;
}

.selectionBoxStyle:hover .productName,
.selectionBoxStyle.active .productName {
  border-bottom: 1px solid #fff;
}

.productDesc,
.productPrice {
  font-size: 20px;
  margin-top: 0;
}

.productDesc span:not(:first-child) {
  font-size: 18px;
  display: inline-block;
  margin-top: 5px;
}

.planWrap input {
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.planWrap svg,
.selectionBoxStyle svg {
  position: absolute;
  top: 50%;
  right: 15px;
  border-radius: 50%;
  border: 1px solid #9f1c44;
  width: 20px;
  height: 20px;
  padding: 5px;
}

.selectionBoxStyle:hover svg,
.selectionBoxStyle.active svg {
  border: 1px solid #fff;
}

.planWrap svg circle {
  display: none;
}

.planWrap input:checked~svg circle {
  display: block;
}

.promoForm>div {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.promoForm button {
  padding: 0;
  width: 150px;
}

/* .stripeWarp {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  padding: 20px;
  background: #00000038;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .stripeForm {
  background-color: #9f1c44;
  border-radius: 21px;
  width: 468px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
} */

.stripeForm button {
  margin-top: 20px;
}

.subsPoints h3 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: -5px;
}

.subsPoints ul {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
}

.subsPoints li {
  margin: 10px 0;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  transform-origin: 0px 10.2px;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.trialStatus {
  display: inline-block;
  font-size: 14px;
  background: #fdecf4;
  color: #9e0145;
  border-radius: 8px;
  position: relative;
  padding: 0px 5px;
  top: -1px;
  left: 5px;
}

/**
***** New Template for Subscription *****
***/
.newTemplate .centerContent {
  grid-gap: 0px;
  gap: 0px;
  flex-wrap: wrap;
  align-content: space-evenly;
}

.newTemplate .sectionContent {
  border: 2px solid transparent;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  border-radius: 0;
  background: transparent;
  color: #000;
  padding: 15px;
  width: 100%;
  max-width: 310px;
  overflow: hidden;
}

.gift-card-page-page .sectionContent {
  max-width: 370px;
}

.newTemplate .sectionContent.secondSection {
  background: #ff1d9b;
  border: 2px solid #9f1c44;
}

.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  margin: 0px 0 6px;
  text-align: center;
  width: 100%;
}

.newTemplate .sectionContent .innerSection {
  background: #fff;
  border: 3px solid #9f1c44;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
}

.newTemplate .sectionContent .innerSection>* {
  padding: 30px 0;
  width: 100%;
  text-align: center;
}

.newTemplate .titleSection {
  background: #f9e2ed;
  border-bottom: 1px solid #9f1c44;
}

.newTemplate .decsSection {
  min-height: 300px;
}

.newTemplate .planName {
  font-size: 34px;
  font-weight: 500;
  margin: 0px;
}

.newTemplate .planName2,
.newTemplate .planTrial {
  font-size: 28px;
  font-weight: 300;
  margin: 0;
}

.newTemplate .planPrice {
  font-size: 40px;
  margin: 8px;
}

.newTemplate .planPrice span {
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-top: -2px;
  margin-bottom: -8px;
}

.newTemplate .planPrice small {
  color: red;
  display: block;
  font-size: 30px;
}

.subscribe-page .newTemplate .thirdSection .planPrice {
  /* margin-top: -25px; */
}

.newTemplate .subsPoints ul {
  padding-left: 40px;
}

.newTemplate .subsPoints ul li {
  font-size: 18px;
  font-weight: 300;
  position: relative;
  text-align: left;
}

.newTemplate .subsPoints ul li:before {
  content: " ";
  position: absolute;
  left: -15px;
  border: 3px solid #000;
  border-radius: 25px;
  top: 5px;
}

.newTemplate .subsPoints ul li span {}

.newTemplate .footerSection {
  background: #f9e2ed;
  border-top: 1px solid #9f1c44;
}

.newTemplate .footerSection button {
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 30px;
  font-weight: normal;
}

.newTemplate .sectionContent.lowerContent {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin-top: -30px;
}

.popupSection {
  background: #00000045;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.popupOverlay {
  background: #00000045;
  width: 100vw;
  height: 100vh;
}

.popupContent {
  border: 3px solid #9f1c44;
  background: #fff;
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  translate: 0 -50%;
  padding: 50px;
  min-height: 600px;
}

.popupContent .sectionTitle {
  font-size: 38px;
  margin-bottom: 20px;
}

.popupContent .inputStyle {
  border-radius: 0;
  border: 1px solid #9f1c44;
  color: #000;
}

.popupContent .socialButton {
  background: #f9e2ed;
  border: 3px solid #9f1c44;
  border-radius: 0;
  color: #000;
  transition: background 0.8s;
  font-size: 20px;
  font-weight: 500;
  padding: 15px;
}

.popupContent .socialButton:hover {
  background: #9f1c44;
  color: #fff;
  transition: background 0.8s;
}


/**
*****
***/
.subscribe-yearly-100-page .subsPoints li svg path {
  fill: #9f1c44;
}


@media screen and (max-width: 900px) {

  .siderBarMenu ul a,
  .siderBarMenu ul button {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .siderBarMenu {
    flex-direction: column;
    position: relative;
    z-index: 1;
    background-color: #fff;
  }

  .siderBarMenu header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .mobilemenuIcon {
    cursor: pointer;
    display: inline-flex;
    height: 35px;
    width: auto;
  }

  .menuList {
    flex-direction: column;
    width: 100%;
    margin-top: 50px;
    background-color: #fff;
  }

  .menuList:after {
    position: absolute;
    display: block;
    width: 200vw;
    height: 200vh;
    background: #fff;
    content: " ";
    z-index: -1;
    top: -50%;
    left: -50%;
  }


  .siderBarMenu li {
    border-bottom: 2px solid #e2dacf32;
    min-height: 70px;
  }

  .siderBarMenu ul a,
  .siderBarMenu ul button {
    display: block;
    font-size: 24px;
    height: 70px;
    line-height: 70px;
    width: 100%;
    padding-right: 30px;
    position: relative;
  }

  .siderBarMenu ul svg {
    display: inline;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
  }

  .appDownloadIcons {
    display: flex;
    padding-top: 50px;
    background-color: #fff;
    width: 100%;
  }

  .appDownloadIcons a {
    display: flex;
    padding: 0;
    margin: 10px 0;
    width: 200px;
  }

  .appDownloadIcons a svg {
    width: 100%;
    height: auto;
  }

  .accountBox {
    height: auto;
  }

  .landingPage .centerContent {
    flex-direction: column;
  }

  .landingPage .sectionContent {
    margin-bottom: 50px;
  }

  .landingPage.newTemplate .centerContent {
    flex-direction: row;
  }

  .newTemplate .sectionContent {
    padding: 0;
    width: 31%;
  }

  .newTemplate .sectionContent.lowerContent {
    width: 100%;
  }

  .landingPage.newTemplate .sectionContent.secondSection {
    border: 0;
  }

  .landingPage.newTemplate .sectionContent .sectionHeading {
    font-size: 27px;
    padding-top: 5px;
  }

  .landingPage.newTemplate .planName2,
  .landingPage.newTemplate .planTrial {
    font-size: 24px;
  }

  .landingPage.newTemplate .planName1,
  .landingPage.newTemplate .planPrice {
    font-size: 32px;
  }

  .newTemplate .footerSection button {
    font-size: 22px;
  }
}

@media screen and (max-width: 665px) {
  .mobile_hide {
    display: none !important;
  }

  .mobile_show {
    display: block !important;
  }

  .siderBarMenu li {
    border-bottom: none;
    min-height: auto;
    margin-bottom: 25px;
  }

  .siderBarMenu ul a,
  .siderBarMenu ul button {
    height: auto;
    line-height: normal;
  }

  .siderBarMenu ul svg {
    height: auto;
  }

  .landingPage {
    padding: 0 30px !important;
  }

  .thankyou-page .landingPage {
    /* background: #9f1c44 !important; */
  }

  .popupContent {
    padding: 50px 30px;
    width: 85%;
  }
}

@media screen and (max-width: 468px) {
  .sectionContent {
    height: 100%;
    border-radius: 0;
  }

  .landingPage .sectionContent {
    width: 100%;
    padding: 40px 20px;
  }

  label.trialLbl.planWrap {
    padding-top: 45px;
  }

  .productTrial {
    right: 15px;
    top: 15px;
  }

  .productDesc span {
    font-size: 14px;
  }

  .landingPage.newTemplate .sectionContent {
    padding: 0;
  }
}
